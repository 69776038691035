// src/pages/Home.js
import React from 'react';
import './Home.css';

function Home() {
  return (
    <div>
      <section id="about" className="App-section">
        <div className="home-text-image-container">
          <div className="home-text-content">
            <h2 className="home-heading">Revolutionize Your Fast-Food Experience with Franky</h2>
            <p>
              In today’s fast-paced world, time is everything. Long lines at the counter and waiting for delivery can feel like a waste of valuable moments. That’s where Franky steps in. Our app is built to provide an effortless solution for busy customers who want to skip the wait without paying extra delivery fees. Whether you’re at work, running errands, or simply craving a quick meal, Franky helps you get your food fast — without the hassle.
            </p>
          </div>
          <img src="/Franky-app.png" alt="Franky Manager" className="franky-image" />
        </div>

        <div className="home-second-text-content">
            <h2 className="home-heading">Why Choose Franky?</h2>
            <ul>
              <li><strong>Skip the Line:</strong> Say goodbye to long waits during lunch hour. Order your food in advance and pick it up at your convenience — no lines, no waiting.</li>
              <li><strong>Convenience at Your Fingertips:</strong> With just a few taps, place your order and pick up your food on the go. Whether you’re on your way to work or headed to a meeting, Franky ensures you have more time for what matters.</li>
              <li><strong>Tech-Driven, Self-Service:</strong> In an age where technology drives customer satisfaction, Franky empowers users to skip the traditional in-store experience and place their order digitally. The rise of self-service kiosks in restaurants like McDonald's shows just how much people appreciate convenience and speed.</li>
            </ul>
        </div>

        <div className="home-third-text-content">
            <h2 className="home-heading">Industry Insights</h2>
            <p>
              The food delivery and fast-casual dining industry is growing at an impressive rate. According to recent data from Statista, the global online food delivery market was valued at over $150 billion in 2022 and is expected to reach $300 billion by 2030. This growth is driven by the increasing demand for faster, more convenient dining experiences.
            </p>
            <p>
              As more customers seek ways to avoid delivery fees and the frustrations of waiting in line, Franky provides the perfect solution for businesses looking to meet this demand while improving customer satisfaction. In fact, 60% of consumers report using technology to avoid waiting in line, and 50% prefer digital ordering for its speed and convenience.
            </p>
        </div>

        <div className="home-fourth-text-content">
            <h2 className="home-heading">Features That Make Franky Stand Out</h2>
            <ul>
              <li><strong>Customizable Promotions:</strong> Drive sales with exclusive offers that only Franky users can access.</li>
              <li><strong>Loyalty Rewards:</strong> Reward your regular customers with points they can redeem for discounts or free items.</li>
              <li><strong>Flash Offers:</strong> Create time-sensitive deals to increase sales during slow hours or reduce excess inventory.</li>
            </ul>
        </div>

        <div className="home-fifth-text-content">
            <h2 className="home-heading">Join the Revolution</h2>
            <p>
              Ready to offer your customers the convenience they crave? Whether you own a single store or multiple locations, Franky can help you boost your sales, improve customer loyalty, and provide a seamless experience for your patrons.
            </p>
            <p>
              Download Franky Today and Experience the Future of Fast-Food Ordering.
            </p>
        </div>
      </section>
    </div>
  );
}

export default Home;
