import React from 'react';
import './Footer.css'; // Import the Footer CSS

function Footer() {
  return (
    <footer className="App-footer">
      {/* Left section: Contact Information */}
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p><strong>Email:</strong> contact@itsfranky.com</p>
        <p><strong>Phone:</strong> (+56) 1234 56789</p>
        <p><strong>Location:</strong> Santiago, Chile</p>
      </div>

      {/* Right section: Logo */}
      <div className="logo">
        <img src="/Franky_Logo.jpg" alt="Franky Logo" />
      </div>
    </footer>
  );
}

export default Footer;
