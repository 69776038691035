// src/pages/wwu.js
import React from 'react';
import './Wwu.css';

function Wwu() {
  return (
    <div>
      <section id="about" className="App-section">
        <div className="wwu-text-content">
          <h2 className="wwu-heading">Partner with Franky: Transform Your Store’s Ordering Experience</h2>
          <p>
            In today’s competitive fast-food industry, customer satisfaction is everything. As the demand for convenience grows, businesses need to adapt to meet modern expectations. Franky is your solution to stay ahead of the curve by offering customers a seamless, fast, and hassle-free ordering experience. We are offering you an opportunity to increase efficiency, drive sales, and enhance customer loyalty—all while keeping things simple and cost-effective.
          </p>
        </div>

        <div className="wwu-second-text-content">
          <h2 className="wwu-heading">Why Partner with Franky?</h2>
          <p>
            Franky isn’t just another app—it's a comprehensive platform designed to help your business thrive in the digital age. Here’s how we make a difference:
          </p>
          <ul>
            <li><strong>Boost Sales and Customer Engagement:</strong> With Franky, you can offer exclusive promotions, loyalty programs, and flash offers tailored to your customers’ needs. Whether it’s attracting new customers or rewarding your most loyal patrons, Franky helps you create meaningful interactions.</li>
            <li><strong>Increase Efficiency:</strong> By allowing customers to place orders online and pick them up in-store, you reduce wait times, optimize order fulfillment, and streamline operations. This means faster service, fewer customer complaints, and happier patrons.</li>
            <li><strong>No Delivery Fees:</strong> Unlike third-party delivery services, Franky charges a flat monthly fee per store—no hidden costs or per-order fees. This makes it a more affordable and predictable solution for growing your business.</li>
            <li><strong>Comprehensive Support:</strong> Our team is committed to helping you get the most out of your partnership with Franky. From onboarding to ongoing support, we ensure that you have everything you need to succeed.</li>
          </ul>
        </div>

        <div className="wwu-third-text-content">
          <h2 className="wwu-heading">Exclusive Features for Your Business</h2>
          <p>
            Franky provides a range of features designed to boost your business:
          </p>
          <ul>
            <li><strong>Customizable Promotions:</strong> Run tailored campaigns through Franky that only your customers can access. Offer discounts, special deals, or exclusive menus to keep your customers engaged.</li>
            <li><strong>Loyalty Points System:</strong> Reward repeat customers with points that can be redeemed for free items, discounts, or special perks. A great way to build a loyal customer base and keep people coming back.</li>
            <li><strong>Flash Offers:</strong> Set time-sensitive or limited-quantity offers to drive sales during off-peak hours or clear excess inventory. You can also make these offers exclusive to customers with loyalty points, ensuring the right people get the best deals.</li>
          </ul>
        </div>

        <div className="wwu-fourth-text-content">
          <h2 className="wwu-heading">Affordable and Flexible Pricing</h2>
          <p>
            We understand that every store is different, which is why we offer a flat monthly fee of <strong>500,000 CLP</strong> per store, giving you access to all of Franky’s features. There are no per-transaction fees, which means you can plan your costs with certainty.
          </p>
          <p>
            If you'd prefer a different payment structure, we're flexible and happy to discuss options that best fit your business needs.
          </p>
        </div>

        <div className="wwu-fifth-text-content">
          <h2 className="wwu-heading">Why Franky?</h2>
          <p>
            The foodservice industry is evolving, and Franky is here to help your business stay ahead of the trends. By adopting Franky, you’ll not only improve your customer experience, but you'll also tap into a growing demand for digital ordering solutions. With the global food delivery market projected to reach $300 billion by 2030, now is the time to make your store a part of this fast-moving revolution.
          </p>
        </div>

        <div className="wwu-sixth-text-content">
          <h2 className="wwu-heading">Get Started with Franky Today</h2>
          <p>
            Ready to bring your store into the future of fast-food ordering? Signing up is easy, and our team is here to guide you every step of the way. Simply reach out to us at <a href="mailto:contact@itsfranky.com">contact@itsfranky.com</a> to get started or ask any questions you may have.
          </p>
          <p>
            Let’s work together to make your store the go-to spot for customers looking for speed, convenience, and great food.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Wwu;
