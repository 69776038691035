// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-us-page">
      <div className="about-us-section">
        <img src="CEO.png" alt="This is our CEO" className="about-us-image" />
        <div className="about-us-text">
          <h2 className='about-us-heading'>Kristofher Muñoz - <strong>CEO</strong></h2>
          <p>This is our amazing CEO</p>
        </div>
      </div>

      <div className="about-us-section">
        <img src="CTO.png" alt="This is our CTO" className="about-us-image" />
        <div className="about-us-text">
          <h2 className='about-us-heading'>Gaspar Pizarro - <strong>CTO</strong></h2>
          <p>This is our amazing CTO</p>
        </div>
      </div>

      <div className="about-us-section">
        <img src="PM.png" alt="This is me" className="about-us-image" />
        <div className="about-us-text">
          <h2 className='about-us-heading'>Jaime Cerda - <strong>Product Manager</strong></h2>
          <p>This is our amazing product manager</p>
        </div>
      </div>

    </div>
  );
}

export default AboutUs;
